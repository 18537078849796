import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { bindActionCreators } from "redux";
import { updateWindowWidth } from "../../reducers/page";
import { connect } from "react-redux";
import ContentTitle from "./ContentTitle";
import ContentLeft from "./ContentLeft";
import Image from "react-bootstrap/Image";
import merge from "lodash.merge";
import cloneDeep from "lodash.clonedeep";


class CurrenciesSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: props.style,
            ...props,
            ...{
                title: props.title,
                details: props.details,
                customerImage: props.customerImage,
                imageLocation: props.imageLocation,
                mobileWidthThreshold: props.mobileWidthThreshold,
                imageTitle: props.imageTitle,
                imageSide: props.imageSide,
            },
            ...{windowWidth: window.innerWidth},
        };
        console.log('---> hrefName: ', this.state.hrefName);
    }

    handleResize = (e) => {
        this.state.updateWindowWidth(window.innerWidth);
        this.setState(() => ({ windowWidth: window.innerWidth}));
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    renderSmallScreens() {
        const style = merge(cloneDeep(this.state.style), {overflowX: "hidden"});
        return (
            <Container
                fluid
                className="m-0 p-2"
                style={style}
            >
                <ContentTitle
                    alignment='center'
                    className="col-sm-12 col-md-12 pt-0"
                    style={{color: '#FFF'}}
                >
                    {this.state.title}
                </ContentTitle>

                <div className="text-center" style={{paddingTop: 15}}>
                    {this.state.details}
                </div>
                <div className="d-flex justify-content-center" >
                    <div >
                        <Image fluid src={this.state.customerImage} />
                    </div>
                </div>



            </Container>
        );
    }

    renderMediumScreenTitleAndText() {
        const style = merge(cloneDeep(this.state.style), {overflowX: "hidden"});
        return (
            <Col >
                <ContentTitle
                    alignment='left'
                    className="pb-md-1"
                    style={style}
                >
                    {this.state.title}
                </ContentTitle>
                <ContentLeft>
                    {this.state.details}
                </ContentLeft>
            </Col>
        );
    }

    renderMediumScreenImage() {
        const style = merge(cloneDeep(this.state.style));
        return (
            <Col>
                <div className="d-flex justify-content-center ">
                    <div>
                        <Image fluid src={this.state.customerImage} />
                    </div>
                </div>
            </Col>
        );
    }

    renderMediumScreens() {
        let leftContent, rightContent;
        if (this.state.imageSide === 'left') {
            leftContent = this.renderMediumScreenImage();
            rightContent = this.renderMediumScreenTitleAndText();
        } else {
            leftContent = this.renderMediumScreenTitleAndText();
            rightContent = this.renderMediumScreenImage();
        }
        const style = merge(cloneDeep(this.state.style), {overflowX: "hidden"});

        return (
            <Container
                fluid
                className="m-0 p-2"
                style={style}
            >
                <Row className="align-items-center">
                    {leftContent}
                    {rightContent}
                </Row>
            </Container>
        );
    }

    render() {
        if (this.state.windowWidth >= this.state.mobileWidthThreshold) {
            return this.renderMediumScreens();
        } else {
            return this.renderSmallScreens();
        }
    }
}


CurrenciesSection.defaultProps = {
    title: 'A Section Title',
    details: "Lorum ipsum and stuff",
    imageLocation: 'center',
    mobileWidthThreshold: 768,
    imageTitle: null,
    imageSubtitle1: null,
    imageSubtitle2: null,
    imageSubtitle3: null,
};

const mapStateToProps = (state) => {
    const { page } = state;
    return { page };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateWindowWidth,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CurrenciesSection);

