import React, { Component } from "react";
import "./MyAccount.css";
import Content from "../../Components/Content/Content";

class MyAccount extends Component {
	render() {
		return (
			<div className="MyAccount">
				<Content className="Content">
					<h3>My Account</h3>

				</Content>
			</div>
		);
	}
}

export default MyAccount;
