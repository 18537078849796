import React, { Component } from "react";

const styles = {
    root: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#FF6600',
    },
};

class ContentScreenshotsTitle extends Component {
    constructor(props) {
        super(props);
        const {color} = props;
        this.state = {...props};
        this.state.style = {...styles.root, ...props.style};
        if (color) {
            this.state.style.color = color;
        }
    }

    render() {
        return (
            <div
                style={this.state.style}
                className="pb-md-1"
            >
                {this.props.children}
            </div>
        );
    }
}


ContentScreenshotsTitle.defaultProps = {
    style: styles.root,
};



export default ContentScreenshotsTitle;

