import React, { Component } from "react";
import Content from "../Components/Content/Content";
import multipleCurrenciesImage from "../assets/img/section-graphics/multiple-crypto-currencies.png";
import BackgroundImage from "../Components/Content/BackgroundImage";
import SectionTitle from "../Components/Content/SectionTitle";
import bitcoinImage from "../assets/img/cards/bitcoin-on-white-background.png";
import ethereumImage from "../assets/img/cards/eth-coin-on-white-background.png";
import oneHundredDollarBillImage from "../assets/img/cards/one-hundred-dollar-bill.png";
import CurrenciesSection from "../Components/Content/CurrenciesSection";
import { ReactURLs } from "../globals/urls";


class Currencies extends Component {
	renderMultipleCurrenciesVerbiage() {
		return (
			<div>
				<SectionTitle>Multiple Currencies Accepted Here</SectionTitle>
				<p style={styles.sectionDetail}>
					TODO: Insert a summary of crypto and fiat currencies used by Crypto Ninja.
				</p>
			</div>
		);
	}

	render() {
		const bitcoinSectionDetails = (
			<div style={{color: "white"}}>
				<p>
					Bitcoin is by far the most popular cryptocurrency out there today. It is more accessible, with more
					exchanges, more merchants, more software and more hardware that support it. Bitcoin is far more
					liquid, with much larger volumes than every crypto option. Bitcoin has the largest developer
					ecosystem with more software and more implementations than any others. Bitcoin has the most
					entrepreneurs creating companies around it with a lot of intellect, dedication and creativity
					going toward making it more useful. As its legitimacy in the minds of mainstream consumers
					continues to increase, owners of bitcoin will seek out more methods and outlets to spend it.
					Let your business be one of them.
				</p>
				<p>
					<strong>In Circulation:</strong> 18,730,375 BTC<br/>
					<strong>Market Cap:</strong> $628.6 Billion USD<br/>
					<strong>Americans Who Own Bitcoin:</strong> ~46 Million (14%)
				</p>
			</div>
		);
		const ethereumSectionDetails = (
			<div style={{color: "white"}}>
				<p>
					Ethereum is second only in popularity to Bitcoin. Proponents of Ethereum believe its main advantage
					over Bitcoin is that it allows individuals and companies to do much more than just transfer money
					between entities leading Bloomberg to recently write that it’s “the hottest platform in the world
					of cryptocurrencies and blockchains” and companies such as JPMorgan Chase, Intel and Microsoft to
					invest in it.
				</p>
				<p>
					<strong>In Circulation:</strong> 116,212,198 ETH<br/>
					<strong>Market Cap:</strong> $292.7 Billion USD<br/>
					<strong>Americans Who Own Ethereum:</strong> ~26 Million (8%)

				</p>
			</div>
		);
		const usDollarSectionDetails = (
			<div>
				<p>
					TODO: Insert a summary of interesting facts about US dollars.
				</p>
				<p>
					<strong>In Circulation:</strong> 1,600 Billion USD<br/>
					<strong>Market Cap:</strong> $1,600 Billion USD
				</p>
			</div>
		);
		return (
			<Content className="Content">
				{/* Section 1 Details */}
				<div className="m-0 p-0" style={styles.whiteBackgroundColor}>
					<BackgroundImage
						bgImage={multipleCurrenciesImage}
						bgImageAlt="Bitcoin and Ethereum accepted here"
					>
						{this.renderMultipleCurrenciesVerbiage()}
					</BackgroundImage>
				</div>

				{/* Bitcoin Section Details */}
				<a name={ReactURLs.hrefBitcoin}/>
				<div className="m-0 p-0 p-md-4" style={styles.bitcoinBackgroundColor}>
					<CurrenciesSection
						title="Bitcoin"
						details={bitcoinSectionDetails}
						customerImage={bitcoinImage}
						imageSide='left'
						style={styles.bitcoinBackgroundColor}
					/>
				</div>

				{/* Ethereum Section Details */}
				<a name={ReactURLs.hrefEthereum}/>
				<div className="m-0 p-0 p-md-4" style={styles.ethereumBackgroundColor}>
					<CurrenciesSection
						title="Ethereum"
						details={ethereumSectionDetails}
						customerImage={ethereumImage}
						imageSide='right'
						style={styles.ethereumBackgroundColor}
					/>
				</div>

				{/* US Dollars Section Details */}
				<a name={ReactURLs.hrefUSDollars}/>
				<div className="m-0 p-0 p-md-4" style={styles.usDollarBackgroundColor}>
					<CurrenciesSection
						title="US Dollars"
						details={usDollarSectionDetails}
						customerImage={oneHundredDollarBillImage}
						imageSide='left'
						style={styles.usDollarBackgroundColor}
					/>
				</div>

			</Content>
		);
	}
}


const styles = {
	whiteBackgroundColor: {
		backgroundColor: '#fff',
		color: '#2A7C6F',
	},
	blueBackgroundColor: {
		backgroundColor: '#2A7C6F',
		color: "#D6D6D5",
	},
	bitcoinBackgroundColor: {
		backgroundColor: '#F7931A',
		color: "#FFF",
	},
	ethereumBackgroundColor: {
		backgroundColor: '#454A75',
		color: "#FFF",
	},
	usDollarBackgroundColor: {
		backgroundColor: '#6EBB5B',
		color: "#FFF",
	},
	sectionDetail: {
		textAlign: "left",
		color: "#D6D6D5",
		paddingLeft: 25,
		paddingRight: 15,
		paddingBottom: 10,
	}
};

export default Currencies;
