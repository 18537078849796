import React, { Component } from "react";
import Content from "../../Components/Content/Content";
import BootstrapTable from 'react-bootstrap-table-next';
import Form from "react-bootstrap/Form";
import types from "../../Utilities";
import "./SignUp.css";
import Button from "react-bootstrap/Button";

function formatter(cell, row, rowIndex, extraData) {
	if (types.get(cell) === types.object) {
		return (
			<div>
				{cell.before} <a href={cell.href}>{cell.text}</a> {cell.after}
			</div>
		);
	} else if (types.get(cell) === types.array) {
		return (
			<div>{cell[0]}
				<ul>{
					cell.slice(1, cell.length).map(
						item => <li>{item}</li>
					)}
				</ul>
			</div>
		);
	} else {
		return (cell);
	}
}

const columns = [{
	dataField: 'line',
	text: 'Task',
	style: {},
	formatter: formatter,
}, {
	dataField: 'points',
	text: 'Reward Points',
	style: (cell, row, rowIndex, colIndex) => {
		if (rowIndex % 2 === 0) {
			return {
				backgroundColor: '#81c784'
			};
		}
		return {
			backgroundColor: '#c8e6c9'
		};
	}
}];


class SignUp extends Component {
	render() {
		// const line1 = [
		// 	'Upon completion of the airdrop promotion your NSTAR ' +
		// 	'tokens will be sent to the above address.  You can ' +
		// 	'also add your ERC 20 token address at any time after ' +
		// 	'registering.',
		// ];
		const telegram_group = "#telegram-group";
		const telegram_channel = "#telegram-channel";
		const twitter = "https://twitter.com/CybertronNinja";
		const medium = "https://3mfi.medium.com/";
		const newsletter = "/newsletter";
		const referral_program = "/referralprogram";
		const tableData = [
			{line: '1. Register for your Crypto Ninja airdrop by creating an account.', points: '100 pts'},
			{line: '2. Verify your email, login to your account, and go to your dashboard.', points: '150 pts'},
			{line: {before: '3. Join our ', href: telegram_group, text: 'official group', after: 'on Telegram.'}, points: '250 pts'},
			{line: {before: '4. Join our ', href: telegram_channel, text: 'official channel', after: 'on Telegram.'}, points: '350 pts'},
			{line: {before: '5. Follow Cybertron Ninja on ', href: twitter, text: 'Twitter.', after: ''}, points: '150 pts'},
			{line: {before: '6. Follow Cybertron Ninja on', href: medium, text:'Medium.', after:''}, points: '300 pts'},
			{line: {before: '7. Subscribe to the Cybertron Ninja', href: newsletter, text: 'newsletter.', after: ''}, points: '500 pts'},
			{
				line: [
					'8. Complete at least one of the following:',
					'Link Crypto Ninja to your bank account.',
					'Link Crypto Ninja to your crypto deposit address.',
					'Link Crypto Ninja to your Venmo account.',
				],
				points: '200 pts'
			},
			{
				line: {
					before: '9. Receive 150 points for each',
					href: referral_program,
					text: 'friend you refer',
					after: '(up to 150 referrals).',
				},
				points: '150,000 pts',
			},
			{line: 'Total Possible Points', points: '152,000 pts'},
			{line: 'Estimated Value', points: ' ~ $1,520.00 USD'},
		];
		return (
			<div className="SignUp">
				<Content className="Content">
					<h3>Create user account</h3>
					<Form>
						<Form.Group controlId="signUpFirstName">
							<Form.Label>First Name</Form.Label>
							<Form.Control type="text" placeholder="enter your first name"/>
							<Form.Text></Form.Text>
						</Form.Group>

						<Form.Group controlId="signUpLastName">
							<Form.Label>Last Name</Form.Label>
							<Form.Control type="text" placeholder="enter your last name"/>
							<Form.Text></Form.Text>
						</Form.Group>

						<Form.Group controlId="signUpEmail">
							<Form.Label>Email</Form.Label>
							<Form.Control type="email" placeholder="enter your email"/>
							<Form.Text></Form.Text>
						</Form.Group>

						<Form.Group controlId="signUpPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" placeholder="enter a password"/>
							<Form.Text></Form.Text>
						</Form.Group>

						<Form.Group controlId="signUpPublicKey">
							<Form.Label>ERC 20 Token Address (optional)</Form.Label>
							<Form.Control
								type="password"
								placeholder="for example: 0x0123456789AbcDef0123456789aBcdEf01234567"/>
							<Form.Text></Form.Text>
						</Form.Group>
						<p>
							Upon completion of the airdrop promotion your NSTAR
							tokens will be sent to the above address.  You can
							also add your ERC 20 token address at any time after
							registering.
						</p>
						<span>{<br/>}</span>

						<span>
							<Button className="SignUp-Button" variant="primary" type="submit">Sign Up</Button>
						</span>

						<BootstrapTable
							keyField='id'
							data={ tableData }
							columns={ columns }
							bordered={ false }
						/>
						<p><strong>NOTE:</strong> NSTAR tokens will be distributed after
							the IEO is over.</p>

					</Form>
				</Content>
			</div>
		);
	}
}


export default SignUp;
