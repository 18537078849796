import React, { Component } from "react";
import "./FAQ.css";
import Content from "../../Components/Content/Content";

class FAQ extends Component {
	render() {
		return (
			<div className="FAQ">
				<Content className="Content">
					<h3>FAQ</h3>

				</Content>
			</div>
		);
	}
}

export default FAQ;
