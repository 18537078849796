import React, { Component } from "react";

class SectionTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {...styles.sectionTitle, ...props.style},
        };
    }

    render() {
        return (
            <div style={styles.sectionTitle}>
                {this.props.children}
            </div>
        );
    }
}

const styles = {
    sectionTitle: {
        textAlign: "left",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#D6D6D5",
        paddingLeft: 25,
        paddingTop: 15,
        paddingRight: 15,
        paddingBottom: 10,
    },
};

export default SectionTitle;

