import React, {Component} from "react";
import "./MyMoney.css";
import Content from "../../Components/Content/Content";
import Button from "react-bootstrap/Button";
import BootstrapTable from 'react-bootstrap-table-next';

const columns = [
	{
		dataField: 'created_on',
		text: 'Date',
		style: {},
		sort: true,
	},
	{
		dataField: 'from_address',
		text: 'From address',
		style: {},
		sort: true,
	},
	{
		dataField: 'total',
		text: 'Transaction',
		style: {},
		sort: true,
	},
	{
		dataField: 'fee',
		text: 'Fee',
		style: {},
		sort: true,
	},
	{
		dataField: 'amount',
		text: 'Amount',
		style: {},
		sort: true,
	},
];


class MyMoney extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: "Jan 1, 2021",
			endDate: "Jan 7, 2021",
			subTotal: "200.0",
			subFee: "0.50",
			subAmount: "199.50",
			tableData: [
				{
					id: 1,
					created_on: "01/01/2021",
					from_address: "0xbbaaccc",
					total: "100.00",
					fee: "0.25",
					amount: "99.75",
					sub_total: "200.0",
					sub_fee: "0.50",
					sub_amount: "199.5",
				},
				{
					id: 2,
					created_on: "01/02/2021",
					from_address: "0xccccddddd",
					total: "100.00",
					fee: "0.25",
					amount: "99.75",
					sub_total: "200.00",
					sub_fee: "0.50",
					sub_amount: "199.50",
				},
			]
		};
	}

	render() {
		const defaultSorted = [{
			dataField: 'created_on',
			order: 'desc'
		}];
		return (
			<div className="MyMoney">
				<Content className="Content">
					<h3>My Money</h3>

					<p>All transactions from {this.state.startDate} through {this.state.endDate}.</p>

					<span className="col-2"></span>
					<Button>
						<i className="bi bi-arrow-left-circle-fill"></i>
					</Button>
					<span className="col-8"></span>
					<Button>
						<i className="bi bi-arrow-right-circle-fill"></i>
					</Button>
					<span className="col-2"></span>

					<p>
						<span>{this.state.startDate} </span>
						<i className="bi bi-arrow-right"> </i>
						<span>{this.state.endDate} </span>
					</p>
					<p>
						<span><strong>Transaction total for the week:</strong> </span>
						<span>$ {this.state.subTotal} USD</span>
					</p>
					<p>
						<span><strong>Fee total for the week:</strong> </span>
						<span>$ {this.state.subFee} USD</span>
					</p>
					<p>
						<span><strong>Amount total for the week:</strong> </span>
						<span>$ {this.state.subAmount} USD</span>
					</p>

					<p>
						<BootstrapTable
							keyField='id'
							data={ this.state.tableData }
							columns={ columns }
							bordered={ true }
							defaultSorted={ defaultSorted }
						/>
					</p>

				</Content>
			</div>
		);
	}
}

export default MyMoney;
