import React, { Component } from "react";
import "./Airdrop.css";
import Content from "../../Components/Content/Content";

class Airdrop extends Component {
	render() {
		return (
			<div className="Airdrop">
				<Content className="Content">
					<h3>Airdrop</h3>

				</Content>
			</div>
		);
	}
}

export default Airdrop;
