import React from 'react';
import Content from "../Content/Content";
import "./NotFound.css";

const NotFound = () => (
    <div className="NotFound">
        <Content>
            <h1
                className="NotFound"
                style={{
                    marginTop: 50,
                }}
            >
                404 not found!
            </h1>
            <p
                style={{
                    marginBottom: 50,
                }}
            >The page you requested was not found.</p>
        </Content>
    </div>
);

export default NotFound;