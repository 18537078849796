import React, { Component } from "react";
import {CNBlue} from "./SiteColors";

const styles = {
    root: {
        fontSize: 16,
        fontWeight: 'normal',
        color: CNBlue,
    }
};

class ContentScreenshotsSubTitle extends Component {
    constructor(props) {
        super(props);
        const {color} = props;
        this.state = {...props};
        this.state.style = {...styles.root, ...props.style};
        if (color) {
            this.state.style.color = color;
        }
    }

    render() {
        return (
            <div
                style={this.state.style}
            >
                <div>
                    {this.props.children}
                </div>

            </div>
        );
    }
}


ContentScreenshotsSubTitle.defaultProps = {
    style: styles.root,
};



export default ContentScreenshotsSubTitle;

