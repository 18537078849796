import React, { Component } from "react";
import Content, { H3, P } from "../Components/Content/Content";


class TermsAndConditions extends Component {
	render() {
		return (
			<div className="px-sm-1 px-md-5 py-md-4">
				<Content>
					<H3>About Us</H3>
					<P>
						We have been avid airsoft players for over 20 years.
					</P>
				</Content>
			</div>
		);
	}
}


const styles = {};

export default TermsAndConditions;

