import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { ReactURLs } from "../../globals/urls";


class GooglePlayStore extends Component {
	render() {
		return (
			<div style={style}>
				<a
					href={ReactURLs.appStoreGoogle}
					// TODO: uncomment the following two lines to open Google Play store in a new tab.
					// style={{display: "table-cell"}}
					// target="_blank"
				>
					<Image width="128" src="/img/google-play-store.png" />
				</a>
			</div>
		);
	}
}

const style = {
	margin: 0,
	padding: 0,
};

export default GooglePlayStore;
