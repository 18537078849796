import React, { Component } from "react";
import "./Newsletter.css";
import Content from "../../Components/Content/Content";
import { H3, } from "../../Components/Content/Content";

class Newsletter extends Component {
	render() {
		return (
			<div className="Newsletter">
				<Content className="Content">
					<H3>Newsletter</H3>
				</Content>
			</div>
		);
	}
}

export default Newsletter;
