import React, { Component } from "react";
import "./Privacy.css";
import Content, { TableColumnSubHeading } from "../../Components/Content/Content";
import {
	H1,
	P,
	UL,
	LI,
	SectionTitle,
	Table,
	TableRow,
	TableHeader,
	TableColumn,
} from "../../Components/Content/Content";


class Privacy extends Component {
	render() {
		return (
			<div className="px-sm-1 px-md-5 py-md-4">
				<Content>
					<H1>Privacy Policy</H1>
					<P>
						Last revised: July 26, 2024
					</P>
					<P>
						This Privacy Policy applies to Tac AO LLC (“TacAO”, “our”, “we”, or “us”) and the affiliates and subsidiaries owned and operated by Tac AO LLC, including our websites (“Sites”), Mobile Apps, Standalone Maps, and Products (collectively, the “Service”). This Privacy Policy (“Privacy Policy”) describes how we collect, use, and share Personal Information from users of the Service (“you” or “your”). As used in this Privacy Policy, “Personal Information” means any information relating to an identified or identifiable individual. By using the Service, you consent to this Privacy Policy.
					</P>
					<P>
						Please read the entire Privacy Policy before using the Service.
					</P>
					<P>
						Here’s a few highlights:
						<UL>
							<LI>
								We do not sell your Personal Information and only share it with any third parties that are pivotal to providing the Service.
							</LI>
							<LI>
								Certain features within the Service allow TacAO to collect your geolocation. Collection of location information is limited to when you are using the Service.
							</LI>
							<LI>
								The personal content you submit to the Service (pings, objectives, waypoints, etc.) belongs to you, and you may choose to share it with other users through the Service. TacAO does not disclose individual account data with other users or third parties unless we’re legally compelled to do so.
							</LI>
						</UL>
					</P>
					<P>
						By visiting, accessing, or using the Service, you expressly consent to the collection, use, storage, transfer, processing, and disclosure of your information, including Personal Information, as described in this Privacy Policy. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, DO NOT ACCESS OR USE ANY PART OF THE SERVICE, OR REGISTER FOR AN ACCOUNT. Beyond this Privacy Policy, your use of the Service is also subject to the Terms of Use located at https://tacticalao.com/terms-of-use (“**Terms of Use**”). All capitalized terms not defined in this Privacy Policy have the respective meanings set forth in the Terms of Use.
					</P>
					<P>
						If you are a California resident, please review our California Resident Privacy Notice in the “Your California Resident Privacy Rights” section below for more information about the types of Personal Information we collect and disclose, as well as how to exercise your rights under California law. Please note that any terms defined in the California Consumer Privacy Act of 2018 (“CCPA”) have the same meaning when used in this Privacy Policy.
					</P>
					<SectionTitle>PERSONAL INFORMATION WE COLLECT</SectionTitle>
					<P>
						We may collect a variety of Personal Information from or about you or your devices from various sources, as described below.
					</P>
					<P>
						If you do not provide your information when requested, you may not be able to use the Service if that information is necessary to provide you with the Service or if we are legally required to collect it.
					</P>
					<SectionTitle>PERSONAL INFORMATION YOU PROVIDE TO US</SectionTitle>
					<P>
						<UL>
							<LI>
								<strong>Registration and Profile Information: </strong>
								When you sign up for an account, register to use the Service, enroll in a promotion or sign up for notifications, we will ask you for your username and phone number.  When interacting with the Service, you may also choose to provide other profile information, including photos and other identifying information.
							</LI>
							<LI>
								<strong>Communications: </strong>
								If you contact us directly, we may receive additional information about you. For example, when you contact our support team, we may receive your name, email address, phone number, and the contents of a message or attachments that you may send to us, and other information you choose to provide.
							</LI>
						</UL>
					</P>
					<SectionTitle>PERSONAL INFORMATION WE COLLECT WHEN YOU USE THE SERVICE</SectionTitle>
					<P>
						<UL>
							<LI>
								<strong>Location Information: </strong> When you use certain features of the Service, TacAO receives your precise location information, including GPS coordinates in real time or through browser geolocation APIs (“GPS Location”). We may also collect the precise location of your device when a mobile application with location features is running in the foreground or background, depending on your settings and/or preferences.  We use your location information to provide the Service. If the Service is unable to acquire your GPS Location, certain features of the Service may not be available to you. We also infer your general location information, for example by using your internet protocol (IP) address.
							</LI>
							<LI>
								<strong>Device Information: </strong>
								We may receive information about the device and software you use to access the Service, including IP address, web browser type, operating system version, and push notification tokens, depending on the portions of the Service you access.
							</LI>
							<LI>
								<strong>Text Information: </strong>
								We work with a third-party partner to facilitate SMS text messages when you contact us. We receive information about these communications, such as the date and time of the SMS text message, your phone number, and the content of any SMS text messages.
							</LI>
						</UL>
					</P>

					<SectionTitle>
						HOW WE USE THE INFORMATION WE COLLECT
					</SectionTitle>
					<P>
						<UL>
							<LI>
								<strong>General: </strong>
								We may use the information we collect to operate, manage, and administer your account and the Service; to provide you with customer service; to provide you with support or a more personalized experience; to communicate with you.
							</LI>
							<LI>
								<strong>Sharing of Location Data: </strong>
								Your GPS location data is shared in real-time with other users, but only with users who are participating in the same game as you. Your location data will not be shared with users outside of your current game.
							</LI>
							<LI>
								<strong>Control Over Location Sharing: </strong>
								You have the ability to leave a game at any time. When you leave a game, TacAO immediately stops sharing your location data with other users in that game.
							</LI>
							<LI>
								<strong>Ephemeral Storage of Location Data: </strong>
								TacAO does not store your location data permanently. Instead, your location data is stored in "ephemeral" storage, meaning it is deleted immediately after being distributed to other players in your current game.
							</LI>
							<LI>
								<strong>Communications: </strong>
								We may use the information we collect to send you emails, text messages, and notifications or other communications related to your use of the Service, your account, and to respond to your inquiries or requests. We may also send you promotional emails to notify you about products or services that may be of interest to you.
							</LI>
						</UL>
					</P>

					<SectionTitle>
						HOW WE SHARE THE INFORMATION WE COLLECT
					</SectionTitle>
					<P>
						We do not share or otherwise disclose information we collect from or about you except as described below or otherwise disclosed to you at the time of the collection.
					</P>
					<P>
						<UL>
							<LI>
								<bold>Partners and Affiliates: </bold>
								We may share any information we receive with our partners, corporate affiliates, parents, or subsidiaries for any purpose described in this Privacy Policy.
							</LI>
							<LI>
								<bold>Social Networks and Other Online Services: </bold>
								The Service may allow you to, upon your direction, share information with social networking services. You understand and agree that the use of your information by any social networking websites will be governed by the privacy policies of these third-party platforms and your settings on that platform. We encourage you to review their privacy policies.
							</LI>
							<LI>
								<bold>Merger, Sale, or Other Asset Transfers: </bold>
								We may transfer or disclose your Personal Information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company, or we sell, liquidate, or transfer all or a portion of our assets.
							</LI>
						</UL>
					</P>

					<SectionTitle>
						YOUR CHOICES
					</SectionTitle>
					<P>
						<UL>
							<LI>
								<bold>Location Information: </bold>
								You can prevent your device from sharing precise location information at any time through your device’s operating system settings. However, location is core to certain parts of the Service and without it, you may not be able to successfully use the Service.
							</LI>
							<LI>
								<bold>Marketing Communications: </bold>
								You can unsubscribe from our promotional emails or other messaging via the link provided in the emails/correspondence. Even if you opt out of receiving promotional messages from us, you will continue to receive administrative messages from us.
							</LI>
							<LI>
								<bold>Do Not Track: </bold>
								There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals. See the Advertising Partners section above for more information on adjusting personalization settings on your browser and mobile device.
							</LI>
						</UL>
						<bold>If you choose not to provide us with information we collect, some features of the Service may not work as intended.</bold>
					</P>

					<SectionTitle>
						INFORMATION SECURITY MEASURES
					</SectionTitle>
					<P>
						We make reasonable efforts to protect your information by using physical and electronic safeguards designed to improve the security of the information we maintain. However, as no electronic transmission or storage of information can be entirely secure, we can make no guarantees as to the security or privacy of your information.
					</P>

					<SectionTitle>
						LINKED SITES
					</SectionTitle>
					<P>
						The Service or users of the Service may provide links to other websites, products, or services that we do not own or operate (“<bold>Linked Sites</bold>”). Please be aware that this Privacy Policy does not apply to your activities on these Linked Sites or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.
					</P>

					<SectionTitle>
						DATA RETENTION
					</SectionTitle>
					<P>
						TacAO retains Personal Information as long as reasonably necessary for the stated purpose it was collected.
					</P>

					<SectionTitle>
						CHILDREN’S PRIVACY
					</SectionTitle>
					<P>
						We do not knowingly collect, maintain, or use Personal Information from children under 13 years of age, and no part of the Service is directed to children (though, children between the ages of 13 and 18 may use certain parts of the Service with the permission and supervision of a legal guardian). If you learn that a child has provided us with Personal Information in violation of this Privacy Policy, then you may alert us at support@tacticalao.com.
					</P>

					<SectionTitle>
						YOUR CALIFORNIA RESIDENT PRIVACY RIGHTS
					</SectionTitle>
					<P>
						This section applies to individuals residing in California from whom we collect Personal Information as a business under the CCPA.
					</P>

					<SectionTitle>
						PERSONAL INFORMATION COLLECTION, DISCLOUSRE, AND SALE
					</SectionTitle>
					<P>
						For the purposes of this section, “<bold>Personal Information</bold>” means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household, or as otherwise defined by the California Consumer Privacy Act of 2018 (California Civil Code §§ 1798.100 to 1798.199) and its implementing regulations, as amended or superseded from time to time (“<bold>CCPA</bold>”).
					</P>
					<P>
						Personal Information does not include information that is:
						<UL>
							<LI>
								Lawfully made available from government records.
							</LI>
							<LI>
								Deidentified or aggregated.
							</LI>
							<LI>
								Otherwise excluded from the scope of the CCPA.
							</LI>
						</UL>
					</P>
					<P>
						The chart below provides the categories of Personal Information (as defined by the CCPA) we have collected, disclosed for a business purpose, or sold in the preceding twelve months since this notice was last updated. The examples of Personal Information provided for each category reflect each category’s statutory definition and may not reflect all of the specific types of Personal Information associated with each category.
					</P>

					<P>
						<Table>
							<TableRow>
								<TableHeader>Category</TableHeader>
								<TableHeader></TableHeader>
								<TableHeader>We Collect</TableHeader>
								<TableHeader>We Disclose</TableHeader>
								<TableHeader>We Sell</TableHeader>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>A. Identifiers</TableColumnSubHeading>
									Examples: Name, alias, postal address, unique personal identifier, online identifier, internet protocol address, email address, account name, or other similar identifiers.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">Yes</TableColumn>
								<TableColumn align="center">Yes</TableColumn>
								<TableColumn align="center">No</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>B. Categories of Personal Information in Cal. Civ. Code Section 1798.80(e)</TableColumnSubHeading>
									Examples:  Name, signature, physical characteristics or description, address, or telephone number.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">Yes</TableColumn>
								<TableColumn align="center">Yes</TableColumn>
								<TableColumn align="center">No</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>C. Characteristics of Protected Classifications under California or Federal Law</TableColumnSubHeading>
									Examples: Name, signature, physical characteristics or description, address, or telephone number.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>D. Commercial Information</TableColumnSubHeading>
									Examples: Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>E. Biometric Information</TableColumnSubHeading>
									Examples: Physiological, biological, or behavioral characteristics, including DNA, that can be used, singly or in combination with each other or with other identifying data, to establish individual identity, such as imagery of the iris, retina, fingerprint, face, hand, palm, vein patterns, and voice recordings, from which an identifier template, such as a faceprint, a minutiae template, or a voiceprint.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>F. Internet or Other Electronic Network Activity Information</TableColumnSubHeading>
									Examples: Browsing history, search history, and information regarding a consumer’s interaction with an internet website, application or advertisement.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>G. Geolocation Data</TableColumnSubHeading>
									Examples: Precise physical location.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">Yes</TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">No</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>H. Sensory Information </TableColumnSubHeading>
									Examples: Audio, electronic, visual, or similar information.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>I. Professional or Employment-Related Information</TableColumnSubHeading>
									Examples: Job application or resume information, past and current job history, and job performance information.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>J. Non-Public Education Information (as defined in 20 U.S.C. 1232g; 34 C.F.R. Part 99)</TableColumnSubHeading>
									Examples: Records that are directly related to a student maintained by an educational agency or institution or by a party acting for the agency or institution.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>

							<TableRow>
								<TableColumn>
									<TableColumnSubHeading>K. Inferences Drawn from Personal Information</TableColumnSubHeading>
									Examples: Consumer profiles reflecting a consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn align="center">No</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
								<TableColumn align="center">N/A</TableColumn>
							</TableRow>


						</Table>
					</P>

					<SectionTitle>
						USE OF PERSONAL INFORMATION
					</SectionTitle>
					<P>
						We collect, use, and disclose your Personal Information in accordance with the specific business and commercial purposes below:
						<UL>
							<LI>
								<bold>Providing Services: </bold>
								Providing our services.
							</LI>
							<LI>
								<bold>Communicating: </bold>
								Communicating with you, providing you with updates and other information relating to our services and products, providing information that you request, responding to comments and questions, and otherwise providing customer support.
							</LI>
							<LI>
								<bold>Connecting Third Party Services: </bold>
								Facilitating the connection of third-party services or applications, such as social networks.
							</LI>
							<LI>
								<bold>Marketing: </bold>
								Marketing purposes, such as developing and providing promotional and advertising materials that may be useful, relevant, valuable or otherwise of interest to you.
							</LI>
							<LI>
								<bold>Personalization: </bold>
								Personalizing your experience on our services, such as presenting tailored content.
							</LI>
							<LI>
								<bold>Sending Messages: </bold>
								Sending you text messages or push notifications.
							</LI>
							<LI>
								<bold>Transient Use: </bold>
								Short-term, transient use.
							</LI>
							<LI>
								<bold>Contracting Vendors: </bold>
								Contracting with vendors and service providers to perform services on our behalf or on their behalf, including maintaining or servicing accounts, providing customer service, or verifying customer information.
							</LI>
							<LI>
								<bold>Notified Purpose: </bold>
								For other purposes for which we provide specific notice at the time the information is collected.
							</LI>
						</UL>
					</P>

					<SectionTitle>
						COLLECTION OF PERSONAL INFORMATION
					</SectionTitle>
					<P>
						In the preceding twelve months since this notice was last updated, we have collected Personal Information from the following categories of sources:
						<UL>
							<LI>
								<bold>You/Your Devices: </bold>
								You or your devices directly.
							</LI>
							<LI>
								<bold>Users: </bold>
								Other users of our services.
							</LI>
							<LI>
								<bold>Affiliates.</bold>
							</LI>
							<LI>
								<bold>Advertising Networks.</bold>
							</LI>
							<LI>
								<bold>Analytics Providers.</bold>
							</LI>
							<LI>
								<bold>OS/Platform Provider: </bold>
								Operating systems and platforms.
							</LI>
							<LI>
								<bold>Social Networks.</bold>
							</LI>
							<LI>
								<bold>Partners.</bold>
								Business partners.
							</LI>
							<LI>
								<bold>Public.</bold>
								Publicly accessible sources.
							</LI>
						</UL>
					</P>

					<SectionTitle>
						YOUR CALIFORNIA PRIVACY RIGHTS
					</SectionTitle>
					<P>
						If you are a California resident, you may exercise the following rights:
						<UL>
							<LI>
								<bold>Right to Know and Access: </bold>
								You may submit a verifiable request for information regarding the: (1) categories of Personal Information collected, sold, or disclosed by us; (2) purposes for which categories of Personal Information are collected or sold by us; (3) categories of sources from which we collect Personal Information; (4) categories of third parties with whom we disclosed or sold Personal Information; and (5) specific pieces of Personal Information we have collected about you during the past twelve months.
							</LI>
							<LI>
								<bold>Right to Delete: </bold>
								Subject to certain exceptions, you may submit a verifiable request that we delete Personal Information about you that we have collected from you.
							</LI>
							<LI>
								<bold>Verification: </bold>
								Requests for access to or deletion of Personal Information are subject to our ability to reasonably verify your identity in light of the information requested and pursuant to relevant CCPA requirements, limitations, and regulations. To verify your access or deletion request, please follow the instructions provided in our response to your request.
							</LI>
							<LI>
								<bold>Right to Opt Out: </bold>
								In some circumstances, you may opt out of the sale of your Personal Information. We do not sell your Personal Information.
							</LI>
							<LI>
								<bold>Right to Equal Service and Price: </bold>
								You have the right not to receive discriminatory treatment for the exercise of your CCPA privacy rights, subject to certain limitations. You may opt in to certain financial incentive programs we may offer related to the collection, retention, or sale of your Personal Information.
							</LI>
						</UL>
					</P>


					<SectionTitle>
						OTHER STATE PRIVACY RIGHTS
					</SectionTitle>
					<P>
						If you reside in a state other than California that has implemented state-specific data privacy legislation, your rights to access, confirm, move, correct and/or delete your Personal Information may be covered by the above sections applicable to the CCPA, and may apply to you under such legislation.
					</P>
					<P>
						Should you wish to do any of the following, please contact us at the support@tacticalao.com.
						<UL>
							<LI>
								Access, amend, confirm, delete or transfer any Personal Information.
							</LI>
							<LI>
								Object to the processing of your Personal Information.
							</LI>
							<LI>
								Ask us a question regarding use of your Personal Information.
							</LI>
							<LI>
								Believe there has been a violation of your privacy rights.
							</LI>
						</UL>
					</P>


					<SectionTitle>
						CANADA RESIDENTS
					</SectionTitle>
					<P>
						The Service and our privacy practices are compliant with The Personal Information Protection and Electronic Documents Act (“<bold>PIPEDA</bold>”) fair information principles. In addition to the disclosures made above, we:
						<UL>
							<LI>
								<bold>Have appointed a privacy officer to monitor our compliance with PIPEDA.</bold>
							</LI>
							<LI>
								<bold>Only collect Personal Information for the uses described herein.</bold>
							</LI>
							<LI>
								<bold>Only use and disclose Personal Information as described herein.</bold>
							</LI>
							<LI>
								<bold>Take reasonable steps to verify the accuracy of the Personal Information that we collect.</bold>
							</LI>
							<LI>
								<bold>Take appropriate safeguards to protect the Personal Information, as further described herein.</bold>
							</LI>
						</UL>
					</P>
					<P>
						If you are a Canada resident, you have the right to request access to the existence, use and disclosure of your Personal Information. Additionally, you have the right to challenge the accuracy and completeness of the information and request to have it amended as appropriate. Finally, you have the right to challenge our compliance with the PIPEDA fair information principles by contacting our privacy officer. To exercise your rights under this Section, please send an e-mail to support@tacticalao.com  or write us at the address listed in the section titled "CONTACT INFORMATION" at the bottom of this Privacy Policy.
					</P>

					<SectionTitle>
						EEA/UK AND OTHER INTERNATIONAL RESIDENTS
					</SectionTitle>
					<P>
						While the Service is not intended for use by EEA or other international residents, if you are a resident of the European Economic Area (“EEA”), United Kingdom (“UK”) or another international jurisdiction using the Service.
					</P>
					<P>
						Should you with to do any of the following, please contact us at support@tacticalao.com.
						<UL>
							<LI>
								Access, amend, confirm, delete or transfer anyPersonal Information.
							</LI>
							<LI>
								Object to the processing of your Personal Information.
							</LI>
							<LI>
								Ask us a question regarding use of your Personal Information.
							</LI>
							<LI>
								Believe there has been a violation of your privacy rights.
							</LI>
						</UL>
					</P>

					<SectionTitle>
						OTHER INTERNATIONAL VISITORS
					</SectionTitle>
					<P>
						The Service is hosted in the United States and intended for visitors located within the United States and Canada. If you choose to use the Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your Personal Information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Service. By providing any information, including Personal Information, on or to the Service, you consent to such transfer, storage, and processing.
					</P>

					<SectionTitle>
						ACCESSING OR UPDATING YOUR INFORMATION
					</SectionTitle>
					<P>
						You may access or update your Personal Information by contacting us at: support@tacticalao.com.
					</P>

					<SectionTitle>
						CHANGES TO THIS PRIVACY POLICY
					</SectionTitle>
					<P>
						We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share Personal Information previously collected from you through the Service, we will notify you through the Service, by email, or other communication.
					</P>

					<SectionTitle>
						CONTACT INFORMATION
					</SectionTitle>
					<P>
						TacAO is responsible for processing your information. If you have any questions, comments, or concerns about our processing activities, please email us at: support@tacticalao.com; or
					</P>
					<P>
						write to us at:
					</P>
					<P>
						Tac AO LLC<br></br>
						3880 West Seagull Dr<br></br>
						West Valley, UT 84120
					</P>

				</Content>
			</div>
		);
	}
}

const styles = {};

export default Privacy;
