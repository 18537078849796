import React, { Component } from "react";
import Content from "../Components/Content/Content";

class Logout extends Component {
	render() {
		return (
			<div className="Logout">
				<Content className="Content">
					<h3>Logout</h3>

				</Content>
			</div>
		);
	}
}

export default Logout;
