import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Faq from 'react-faq-component';
import ContentTitle from "./ContentTitle";
import { ReactURLs } from "../../globals/urls";
import {CNBlue} from "./SiteColors";


class PageLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {...props};
    }

    renderHeaderTitle(titleName) {
        return (
            <div
                style={{
                    fontSize: this.state.headerFontSize,
                    color: this.state.fontColor,
                    paddingBottom: 10,
                }}
            >
                {titleName}
            </div>
        );
    }

    renderLink(text, url) {
        return (<div><a href={url} style={{color: this.state.fontColor}}>{text}</a></div>);
    }

    render() {
        const currenciesTitle = this.renderLink(
            this.renderHeaderTitle("Currencies"),
            ReactURLs.curencies,
        );
        const aboutUsTitle = this.renderLink(
            this.renderHeaderTitle("TacAO"),
            ReactURLs.home,
        );
        const supportTitle = this.renderLink(
            this.renderHeaderTitle("Support"),
            ReactURLs.support,
        );
        return (
            <div className={this.state.className} style={this.state.style}>
                <Row>
                    <Col>
                        {aboutUsTitle}
                        {this.renderLink("Contact Support", ReactURLs.support)}
                        {this.renderLink("Privacy Policy", ReactURLs.privacy)}
                        {this.renderLink("Deleting Your Account", ReactURLs.deletingYourAccount)}
                        {this.renderLink("Terms and Conditions", ReactURLs.termsAndConditions)}
                    </Col>
                </Row>
            </div>

        );
    }
}


PageLinks.defaultProps = {
    className: "m-0 p-0 p-md-4",
    style: {backgroundColor: CNBlue, color: 'white'},
    headerFontSize: 24,
    fontColor: 'white',
};

export default PageLinks;

