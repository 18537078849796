import React, { Component } from "react";
import Image from "react-bootstrap/Image";

class EthIcon extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			width: props.width
		};
	}
	
	static defaultProps = {
		width: "100%"
	}
	
	render() {
		return (
			<div className="EthIcon">
				<Image src="/img/eth.jpeg"
					style={{width: this.state.width}}/>
			</div>
		);
	}
}

export default EthIcon;
