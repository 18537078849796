import axios from 'axios';
import { RestApiURLs } from './urls';
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./local_storage_interface";
import { getValueFromLocalStorage, setValueToLocalStorage, clearValueFromLocalStorage } from "./local_storage_interface";

/**
 * GET a refreshed JWT token from the REST API and cache in local storage.
 *
 * @returns {Promise<boolean>}
 */
async function postRefreshTokenRequest() {
    return axios({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getValueFromLocalStorage(ACCESS_TOKEN)}`,
        },
        data: {
            refresh: getValueFromLocalStorage(REFRESH_TOKEN),
        },
        url: `${RestApiURLs.refreshAuthToken}`,
    })
        .then(response => {
            setValueToLocalStorage(ACCESS_TOKEN, response.data.access);
            setValueToLocalStorage(REFRESH_TOKEN, response.data.refresh);
            return response;
        })
        .catch(async e => {
            clearValueFromLocalStorage(ACCESS_TOKEN);
            clearValueFromLocalStorage(REFRESH_TOKEN);
            throw e;
        });
}

/**
 * Build HTTP request body.
 *
 * @param {string} url
 * @param {string} method
 * @param {any} data
 * @param {any} headers
 */
export function buildHTTPRequestBody(url, method, data= null, headers = null) {
    let body = {
        method: 'post',
        url: url,
    }
    if (data != null) {
        body.data = data;
    }
    if (headers != null) {
        body.headers = headers;
    }
    return body;
}

/**
 * Build HTTP request headers.
 *
 * @param contentType
 */
export function buildHTTPRequestHeaders(contentType = 'application/json') {
    let headers = { 'Content-Type': contentType };
    let access_token = getValueFromLocalStorage(ACCESS_TOKEN);
    if (access_token != null) {
        headers.Authorization = `Bearer ${access_token}`;
    }
}

/**
 * Send HTTP request to REST API.
 *
 * @param {string} url
 * @param {string} method as 'get' | 'post' | 'patch' | 'delete'
 * @param {any} data
 * @param {string} contentType as 'multipart/form-data' | 'application/json'
 * @param {any} headers
 */
export function sendHTTPRequest(url,  method, data=null, contentType='application/json', additionalHeaders=null)  {





    let headers = buildHTTPRequestHeaders(contentType);
    let body = buildHTTPRequestBody(url, method, data, headers);
    return axios(body)
        .then(res => res)
        .catch(async e => {

            // If unauthorized, then refresh the JWT tokens.
            if (e.response.status === 401) {
                let refreshToken = getValueFromLocalStorage(REFRESH_TOKEN);
                if (refreshToken == null) {
                    // Refresh token
                    const res = await postRefreshTokenRequest();
                    return res;
                }
            }
            throw e;
        });
}


export const login = (data) => {
    let url = `${RestApiURLs.authToken}`;

    axios.post(
        url,
        data,
        {
            'Content-Type': 'application/json',
        })
        .then((response) => {

            localStorage.setItem(ACCESS_TOKEN, response['data']['access']);
            localStorage.setItem(REFRESH_TOKEN, response['data']['refresh']);
        })
        .catch(async e => {

            window.localStorage.clear();
        });
};

