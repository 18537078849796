import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Faq from 'react-faq-component';
import ContentTitle from "./ContentTitle";

const data = {
    title: "",
    rows: [
        {
            title: "Do I need to own Crypto currency to accept it?",
            content: "No, our app allows you to accept crypto payments which we convert into dollars at the time the transaction is processed."
        },
        {
            title: "How long does it take to sign up and create in account?",
            content: "An account can be set up in as little as 5 minutes."
        },
        {
            title: "How long does it take to receive my payment after a transaction has been completed?",
            content: "You will receive a deposit into your bank account within 2 business days."
        },
        {
            title: "Do I need to accept a certain amount every month?",
            content: "No, you do not need to process a monthly amount, but we want you to use it as much as you can!"
        }],
    styles: {
        bgColor: '#FF6600',
        // titleTextColor: 'blue',
        // titleTextSize: '48px',
        rowTitleColor: 'white',
        rowTitleTextSize: 'medium',
        rowContentColor: '#2A2A2A',
        rowContentTextSize: '16px',
        // rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '50px',
        // rowContentPaddingRight: '150px',
        arrowColor: "white",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    },
};

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            ...{style: props.style},
        };
    }

    render() {
        return (
            <div className="m-0 p-1 p-md-4" style={this.state.style}>
                <ContentTitle
                    alignment="left"
                    style={{color: 'white'}}
                >
                    FAQs
                </ContentTitle>
                <Row>
                    <Col><Faq data={data} styles={data.styles}/></Col>
                    {/*<Col><Faq data={data} styles={data.styles}/></Col>*/}
                </Row>
            </div>

        );
    }
}


FAQ.defaultProps = {
};

export default FAQ;

