// reducers/page.js

const initialState = {
    pageTitle: "",
    windowWidth: null,
};

export const updatePageTitle = pageTitle => (
    {
        type: 'UPDATE_PAGE_TITLE',
        payload: pageTitle,
    }
);

/**
 * Redux Action: set the window width property.
 * @param width
 * @returns {{payload: *, type: string}}
 */
export const updateWindowWidth = width => (

    {
        type: 'UPDATE_WINDOW_WIDTH',
        payload: width,
    }
);

export function pageReducer(state=initialState, action) {
    switch (action.type) {
        case 'UPDATE_PAGE_TITLE': {
            //
            //
            //
            const newState = {
                ...state,
                ...{pageTitle: action.payload},
            };
            return newState;
        }
        case 'UPDATE_WINDOW_WIDTH': {
            const newState = {
                ...state,
                ...{windowWidth: action.payload},
            };
            //
            //
            //
            //
            return newState;
        }
        default:
            return state;
    }
}
