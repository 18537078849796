import React, { Component } from "react";
import Image from "react-bootstrap/Image";


class ContentImage extends Component {
    constructor(props) {
        super(props);
        const {alignment} = props;
        let alignmentClasses;
        if (alignment === "right") {
            alignmentClasses = "float-sm-none float-md-right";
        } else if (alignment === 'left') {
            alignmentClasses = "float-sm-none float-md-left";
        } else {
            alignmentClasses = "float-sm-none float-md-none";
        }
        let width = styles.image.maxWidth;
        if (props.width < styles.image.maxWidth) {
            width = props.width;
        }
        this.state = {
            ...props,
            ...{
                image: props.image,
                alignment: alignmentClasses,
                width: width,
            },
        };
        this.state.style = {...props.style, maxWidth: styles.image.maxWidth};
    }

    render() {
        return (
            <div>
                <Image
                    fluid
                    src={this.state.image}
                    className={this.state.alignment}
                    style={this.state.style}
                    width={this.state.width}
                />
            </div>
        );
    }
}

ContentImage.defaultProps = {
    alignment: 'center',
    width: 400,
};


const styles = {
    image: {
        maxWidth: 400,
    },
};

export default ContentImage;


