import React, { Component } from "react";
import "./Support.css";
import Content, { H1, P, UL, LI } from "../../Components/Content/Content";


class Support extends Component {

	render() {
		return (
			<div className="px-sm-1 px-md-5 py-md-4">
				<Content style={styles.pageContent}>
					<H1>Contacting Support</H1>

					<P>
						If you experience difficulty, need to report a bug, or other issue with TacAO, our support team is standing by
						are ready to assist! To contact our support team send email to: support@tacticalao.com.  Or call (385) 314-3304 between the hours of:
						<UL>
							<LI>
								<strong>Tuesday - Friday:</strong> 11:00 am - 7:00 pm MST
							</LI>
							<LI>
								<strong>Saturday:</strong> 10:00 am - 7:00 am MST
							</LI>
						</UL>
					</P>
				</Content>
			</div>
		);
	}
}


const styles = {
	pageContent: {
		color: '#2A7C6F',
		textAlign: 'left',
		justifyContent: 'left',
	},
};

export default Support;


