export const ACCESS_TOKEN = 'JWT_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'JWT_REFRESH_TOKEN';
export const globalKeys = {
	PAGE_TITLE: 'PAGE_TITLE',
};


export function getValueFromLocalStorage(key) {
	return window.localStorage.getItem(key);
}

export function setValueToLocalStorage(key, value) {
	window.localStorage.setItem(key, value);
}

export function clearValueFromLocalStorage(key) {
	window.localStorage.removeItem(key);
}

/**
 * Test is user is logged in.
 *
 * @returns {boolean}
 */
export function isLoggedIn() {
	let token = getValueFromLocalStorage(ACCESS_TOKEN);
	if(token == null) {
		return false;
	}
	return true;
};



