import React, { Component } from "react";
import "./ReceivePayment.css";
import Content from "../../Components/Content/Content";
import EthIcon from "../../Components/images/EthIcon";
import Container from "react-bootstrap/Container";

/** RecievePayment page for the web-app */
class ReceivePayment extends Component {
	render() {
		return (
			<div className="ReceivePayment">
				<Content>
					<h3>Recieve Payment</h3>
					<Container className="Start">
						<p className="Service-name">
							To recieve a payment, click the button below.
						</p>
						<a href="#placeholder">
							<EthIcon width="100px"/>
						</a>
						<p>Recieve Ethereum Payment</p>
						<p>NOTE: disclaimer disclaimer.....</p>
						<a href="#placeholder">
							Learn more about Ethereum
						</a>
					</Container>
				</Content>
			</div>
		);
	}
}

export default ReceivePayment;
