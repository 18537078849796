import React, { Component } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import {globalKeys, getValueFromLocalStorage} from "../globals/local_storage_interface";
import Container from "react-bootstrap/Container";


class PageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: getValueFromLocalStorage(globalKeys.PAGE_TITLE),
        };
    }

    render()  {
        return (
            <Container
                fluid
                className="m-0 p-0"
                style={{overflowX: "hidden"}}
            >
                <NavBar />
                <div className="m-0 p-0">
                    {this.props.children}
                </div>
                <Footer />
            </Container>
        );
    }
}

export default PageLayout;


