import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { ReactURLs } from "../../globals/urls";


class AppleAppStore extends Component {
	render() {
		return (
			<div style={style}>
				<a
					href={ReactURLs.appStoreApple}
					style={{display: "table-cell"}}
					target="_blank"
				>
					<Image src="/img/apple-app-store.svg"/>
				</a>
			</div>
		);
	}
}

const style = {
	margin: 0,
	padding: 0,
};

export default AppleAppStore;
