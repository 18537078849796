import React, { Component } from "react";
import Content, { H3, P } from "../Components/Content/Content";
import ServiceStatus from "../Components/ServiceStatus/ServiceStatus";
import { CNBlue } from "../Components/Content/SiteColors";


class Status extends Component {

	render() {
		return (
			<Content>
				<H3 style={styles.heading3}>System Status</H3>

				<P style={styles.paragraph}>
					Welcome to the Cybertron Ninja system status page.  We use this
					page to communicate any issues with our products including planned
					and unplanned outages.
				</P>

				<ServiceStatus
					serviceName="Ethereum Blocks"
					serviceDetail="Updates the state of ETH blocks."
				/>
				<ServiceStatus
					serviceName="Ethereum Txns"
					serviceDetail="Updates the state of ETH transactions."
				/>
				<ServiceStatus
					serviceName="Merchant Txn Processor"
					serviceDetail="Confirms merchant transactions."
				/>
				<ServiceStatus
					serviceName="ETH Exchange Rates"
					serviceDetail="Updates ETH exchange rates."
				/>
				<ServiceStatus
					serviceName="ETH Gas Price"
					serviceDetail="Updates ETH gas price."
				/>

			</Content>
		);
	}
}


const styles = {
	pageContent: {
		color: CNBlue,
		textAlign: 'left',
		justifyContent: 'left',
	},
	heading3: {
		color: CNBlue,
		textAlign: 'center',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: '7px',
		paddingTop: '20px',
		marginBottom: 25,
	},
	paragraph: {
		color: CNBlue,
		textAlign: 'center',
		paddingLeft: '10%',
		paddingRight: '10%',
		marginBottom: 35,
	},
};


export default Status;
