import React from "react";
import { Parallax } from "react-parallax";


const BackgroundImage = (props) => {
    const bgImage = props.bgImage;
    const bgImageAlt = props.bgImageAlt ? props.bgImageAlt : '';
    const strength = props.strength ? props.strength : 300;
    const blue = props.blue ? props.blue : 10;
    const style = props.style ? props.style : styles.backgroundImage;

    return (
        <div>
            <Parallax
                blue={blue}
                bgImage={bgImage}
                bgImageAlt={bgImageAlt}
                strength={strength}
            >
                <div style={style}>
                    {props.children}
                </div>
            </Parallax>
        </div>
    );
};


const styles = {
    backgroundImage: {
        height: '500px',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.35)',
    },
};

export default BackgroundImage;




