import React, { Component } from "react";
import "./News.css";
import Content from "../../Components/Content/Content";

class News extends Component {
	render() {
		return (
			<div className="News">
				<Content className="Content">
					<h3>News</h3>

				</Content>
			</div>
		);
	}
}

export default News;
