import React, { Component } from "react";
import Content from "../../Components/Content/Content";
import Form from "react-bootstrap/Form";
import "./Login.css";
import {isLoggedIn} from "../../globals/local_storage_interface";
import {login} from "../../globals/rest_interface";
import Button from "react-bootstrap/Button";

class Login extends Component {
	constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    isFormComplete = () => {
        const { username, password } = this.state;
        return username !== '' && password !== '';
    };

	handleLogin = (event) => {
		event.preventDefault();
        let data = {
            'username': this.state.username,
            'password': this.state.password
        };

        if(!isLoggedIn()) {
            login(data);
        }
    };

	myChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let error = '';
        if (name === "email") {
            if (value == null) {
                error = <strong>Email is required.</strong>;
            }
        }
        this.setState({errormessage: error});
        this.setState({[name]: value});


    };

	render() {
		return (
			<div className="Login">
				<Content className="Content">
					<h3>Login</h3>
					<Form onSubmit={this.handleLogin} >

						<Form.Group controlId="loginEmail">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="enter your email address"
								name="username"
								onChange={this.myChangeHandler}
							/>
							<Form.Text></Form.Text>
						</Form.Group>

						<Form.Group controlId="loginPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="enter a password"
								name="password"
								onChange={this.myChangeHandler}
							/>
							<Form.Text></Form.Text>
						</Form.Group>

						<span className="Login-ForgotPasswordButton">
							<Button variant="outline-primary">Forgot Password</Button>
						</span>
						<span className="Login-ForgotPasswordButton">
							<Button variant="primary" type="submit">Login</Button>
						</span>

					</Form>
				</Content>
			</div>
		);
	}
}

export default Login;
