import React, { Component } from "react";


const styles = {
    root: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2A7C6F'
    },
};

class ContentTitle extends Component {
    constructor(props) {
        super(props);
        const {color} = props;
        let alignment = 'text-center';
        if (props.alignment === 'right') {
            alignment = 'text-right';
        } else if (props.alignment === 'left') {
            alignment = 'text-left';
        }
        this.state = {
            ...props,
            ...{className: alignment + " " + props.className},
        };
        this.state.style = {...styles.root, ...props.style};
        if (color) {
            this.state.style.color = color;
        }
    }

    render() {
        return (
            <div
                className={this.state.className}
                style={this.state.style}
            >
                {this.props.children}
            </div>
        );
    }
}


ContentTitle.defaultProps = {
    style: styles.root,
    alignment: 'center',
};



export default ContentTitle;

