import React, { Component } from "react";
import "./ServiceStatus.css";
import Container from "react-bootstrap/Container";

class ServiceStatus extends Component {
	render() {
		return (
			<Container fluid className="ServiceStatus">
				<p className="Service-name">
					{this.props.serviceName}
				</p>

				<p className="Service-detail">
					{this.props.serviceDetail}
				</p>

				<span className="Service-state">
					Operational
				</span>
			</Container>

		);
	}
}

export default ServiceStatus;
