import React, { Component } from "react";
import Content, { H3, P, UL, LI } from "../Components/Content/Content";


class DeletingYourAccount extends Component {
	render() {
		return (
			<div className="px-sm-1 px-md-5 py-md-4">
				<Content>
					<H3>Deleting Your Account</H3>
					<P>
						You can delete your Tac AO account from within the mobile app.  If you choose to delete your
						account, then all data associated with your account will be immediately deleted.  Once your
						account is deleted, neither it, nor any associated data, will be recoverable.  The data
						associated with your account includes, but is not limited to:
							<UL>
								<LI>Your Tac AO account</LI>
								<LI>All organizations owned by your account</LI>
								<LI>All game fields created by your account</LI>
								<LI>All games created by your account</LI>
							</UL>
					</P>
					<P>
						In order to delete your Tac AO account, do the following steps in the Tac AO mobile app.
						<UL>
							<LI>Tap on your avatar icon in the top right of the screen.</LI>
							<LI>Tap on the menu item labeled "Delete Account".</LI>
							<LI>
								Tac AO will display a confirmation, which reads,
								"Are you sure you want to delete your TacAO account?"
							</LI>
							<LI>Tap on "Yes".</LI>
							<LI>Your account will be immediately and permanently deleted.</LI>
						</UL>
					</P>
				</Content>
			</div>
		);
	}
}


const styles = {};

export default DeletingYourAccount;

