import React, { Component } from "react";


class ContentLeft extends Component {

    render() {
        return (
            <div
                className="text-left"
            >
                {this.props.children}
            </div>
        );
    }
}


export default ContentLeft;

